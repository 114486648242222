import { Map } from 'immutable'
import { coolog as coologAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'
const initialState = Map({})

const handler = {}

handler.getRecentUsageHistoryList = {
  payloadCreator: coologAPI.getRecentUsageHistoryList
}

handler.saveLog = {
  options: { response: { error: { ignore: true } } },
  payloadCreator: coologAPI.saveLog
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
