import axios from 'axios'
import qs from 'qs'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getStores = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/commerce/v1/stores${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getHooks = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/commerce/v1/hooks${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getHookInfo = hookId => {
  const uri = `/commerce/v1/hooks/${hookId}`
  return axios.get(uri)
}

export const createStore = (params = {}) => {
  const uri = `/commerce/v1/stores/naver-smart-store`
  return axios.post(uri, params)
}

export const deleteStore = storeId => {
  const uri = `/commerce/v1/stores/${storeId}`
  return axios.delete(uri)
}

export const inactivateStore = (payload = {}) => {
  const { storeId, reason = '' } = payload
  const uri = `/commerce/v1/stores/${storeId}/inactive`
  return axios.put(uri, { reason })
}

export const createHook = (params = {}) => {
  const uri = `/commerce/v1/hooks`
  return axios.post(uri, params)
}

export const updateHook = (payload = {}) => {
  const { hookId, ...params } = payload
  const uri = `/commerce/v1/hooks/${hookId}`
  return axios.put(uri, params)
}

export const deleteHook = hookId => {
  const uri = `/commerce/v1/hooks/${hookId}`
  return axios.delete(uri)
}

export const addChannel = (payload = {}) => {
  const { channelId } = payload
  const uri = `/commerce/v1/stores/add-channel`
  return axios.post(uri, { channelId })
}

export const getOrderList = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/commerce/v1/orders${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const hookTest = (params = {}) => {
  const { hookId, ...reqParams } = params
  const uri = `/commerce/v1/hooks/${hookId}/test`
  return axios.post(uri, reqParams)
}

export const activateHook = payload => {
  const { hookId } = payload
  return axios.put(`/commerce/v1/hooks/${hookId}/activate`, {})
}

export const inactivateHook = payload => {
  const { hookId } = payload
  return axios.put(`/commerce/v1/hooks/${hookId}/inactivate`, {})
}
