import ifvisible from 'ifvisible.js'

const detectUserStatus = (trigger, ref) => {
  ifvisible.on('blur', function () {
    // example code here..
    // console.log('blur' + moment().format('YYYY-MM-DD HH:mm:ss'))
  })
  ifvisible.on('focus', function () {
    // resume all animations
    // console.log('focus' + moment().format('YYYY-MM-DD HH:mm:ss'))
  })
  ifvisible.on('idle', function () {
    // Stop auto updating the live data
    trigger('idle', ref)
  })
  ifvisible.on('wakeup', function () {
    // go back updating data
    trigger('wakeup', ref)
  })
}
export default (trigger, ref) => {
  detectUserStatus(trigger, ref)
}
