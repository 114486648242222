import axios from 'axios'
import qs from 'qs'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getUserLog = params => {
  const uri = `/serviceName/v1/methodName`
  return axios.get(uri)
}

export const getRecentUsageHistoryList = params => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/coolog/v2/recent-usage-history${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const saveLog = payload => {
  // category
  // UI_SERVER_REQUEST_LOG
  // UI_SERVER_REQUEST_ERROR_LOG
  const uri = `/coolog/v2/logs`
  return axios.post(uri, payload)
}
