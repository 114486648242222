import { diff } from 'deep-object-diff'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

import { isObject, isNumber } from 'lib/detectType'

const checkExpiredToken = token => {
  const decodedToken = jwt.decode(token)
  if (!isObject(decodedToken)) {
    return { decodedToken, isExpired: false, expiredRemainingTime: 0 }
  }
  const browserTime = new Date()
  const expiredRemainingTime =
    Number(decodedToken?.exp) - Number(browserTime.getTime()) / 1000
  const isExpired =
    Number(decodedToken?.exp) < Number(browserTime.getTime()) / 1000
  return { decodedToken, isExpired, expiredRemainingTime }
}
export default (trigger, intervalRef, statusRef) => {
  var checkCookie = (() => {
    var lastCookie = document.cookie
    var last = cookies.get()
    var prevExpired = false
    return () => {
      var currentCookie = document.cookie
      var current = cookies.get()
      const { isExpired: curExpired, expiredRemainingTime } = checkExpiredToken(
        current?.CSAK
      )
      // 토큰 만료 시
      if (curExpired === true && prevExpired !== curExpired) {
        trigger({ tokenExpired: curExpired })
        prevExpired = curExpired
      }
      if (currentCookie !== lastCookie) {
        // 토큰 변경 시
        const diffCookie = diff(last, current)
        const diffCookieKeys = Object.keys(diffCookie)
        if (
          diffCookieKeys.includes('CSAK') ||
          diffCookieKeys.includes('SSCD')
        ) {
          trigger({ current, diff: diffCookie })
        }
        lastCookie = currentCookie
        last = current
      }
      if (
        // 토큰 만료 5분전
        isNumber(expiredRemainingTime) &&
        expiredRemainingTime <= 60 * 5 &&
        expiredRemainingTime > 60 &&
        current?.tokenRefresh === undefined
      ) {
        trigger({
          needTokenRefresh: true,
          expiredRemainingTime,
          userStatus: statusRef?.status
        })
      }
    }
  })()

  if (!intervalRef) {
    intervalRef = setInterval(checkCookie, 500) // run every 500 ms
  }
}
