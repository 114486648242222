/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { useState, useEffect } from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import moment from 'moment-timezone'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

// material UI
// import Grid from '@material-ui/core/Grid'
// import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
// import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
// import CommonButton from 'containers/CommonButtonContainer'

// libraries
// import getComponentId from 'lib/getComponentId'
// import config from 'config'
import { isFunction } from 'lib/detectType'
// import isEqualProps from 'lib/isEqualProps'
// import {
//   makeStyleObject as ms,
//   getClassName as cn,
//   getComponentByBreakpoint as gc
// } from 'lib/getBreakPoints'

export const useProps = {
  //  testVar: { default: 'hello world!', type: 'string.isRequired' }
}
/**
 * 토큰 만료 시각 표기
 */
const TokenExpFormatText = ({ classes: cls, ...props }) => {
  const { onChange } = props
  const { exp = 0 } = jwt.decode(cookies.get('CSAK')) || {}
  const [remainingTime, setRemainingTime] = useState(exp * 1000 - Date.now())
  const duration = moment.duration(remainingTime / 1000, 'seconds')
  useEffect(() => {
    const { exp = 0 } = jwt.decode(cookies.get('CSAK')) || {}
    const countdown = setInterval(() => {
      if (remainingTime > 2000) {
        const newRemainingTime = exp * 1000 - Date.now()
        setRemainingTime(newRemainingTime)
        if (isFunction(onChange)) {
          onChange(newRemainingTime, {
            H: moment.utc(duration.as('milliseconds')).format('H'),
            m: moment.utc(duration.as('milliseconds')).format('s'),
            s: moment.utc(duration.as('milliseconds')).format('s')
          })
        }
      } else {
        setRemainingTime(0)
        if (isFunction(onChange)) {
          onChange(0, { H: 0, m: 0, s: 0 })
        }
        clearInterval(countdown)
      }
    }, 1000)
    return () => clearInterval(countdown)
  }, [remainingTime])
  return moment.utc(duration.as('milliseconds')).format('H시간 m분 s초')
}

TokenExpFormatText.defaultProps = setDefaultProps(useProps)
TokenExpFormatText.propTypes = setPropTypes(useProps)
export default React.memo(TokenExpFormatText, () => {
  return true
})
