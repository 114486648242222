/**
 * @author EdenCha
 * @email eden@nurigo.net
 * @description 네이버 커머스
 */
import { Map } from 'immutable'
import { commerce as commerceAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'

const initialState = Map({
  defaultScript: `function main (context) {\r\n\treturn {}\n}`,
  termsMessage: [
    `이 기능은 베타 서비스로 기능의 변동, 예기치 않은 문제가 발생할 수 있습니다.`,
    `연동된 스토어의 권한을 위임받아 사용합니다.`,
    `주문정보를 보관하여 설정한 자동화에 활용합니다. 개인정보위탁/취급방침에 동의하는 경우에만 체크해주세요.`,
    `메시지 발송 등 유료 동작 사용 시 잔액에서 차감됩니다.`,
    `자동화 생성 및 수정 시각 이후의 주문건만 동작합니다.`,
    `커머스 API 한도 소진, 잔액 소진, 일일 발송 한도 소진 등 외부 요인으로 동작하지 않을 수 있습니다.`,
    `위 사항을 모두 동의하는 경우에만 체크해주세요`
  ].join('\n\n'),
  operations: {
    eq: { label: '일치' },
    ne: { label: '일치하지 않음' },
    gt: { label: '보다 큼' },
    lt: { label: '보다 작음' },
    gte: { label: '이상' },
    lte: { label: '이하' },
    in: { label: '포함' },
    nin: { label: '미포함' },
    regex: { label: '정규식 일치' }
  },
  productOrderStatus: {
    PAYED: '결제완료',
    PAYMENT_WAITING: '결제대기',
    DELIVERING: '배송중',
    DELIVERED: '배송완료',
    PURCHASE_DECIDED: '구매 확정',
    EXCHANGED: '교환',
    CANCELED: '취소',
    RETURNED: '반품',
    CANCELED_BY_NOPAYMENT: '미결제 취소'
  },
  placeOrderStatus: {
    NOT_YET: '발주 미확인',
    OK: '발주 확인',
    CANCEL: '발주 확인 해제'
  },
  targetStatusTable: {
    PAYED: '결제완료',
    PAYMENT_WAITING: '결제대기',
    PREPARING_PRODUCT: '상품준비중',
    DELIVERING: '배송중',
    DELIVERED: '배송완료',
    PURCHASE_DECIDED: '구매 확정',
    EXCHANGED: '교환',
    CANCELED: '취소',
    RETURNED: '반품',
    CANCELED_BY_NOPAYMENT: '미결제 취소'
  },
  // 사용자에게 노출하지 않음
  hideVariableKeys: ['formatData.총주문금액', 'formatData.리뷰작성URL'],
  variableInterface: [
    {
      variableId: 'productName',
      title: '상품명',
      example: '테스트 상품'
    },
    {
      variableId: '주문옵션',
      title: '상품주문옵션',
      example: '각인문구: 각인 안하겠습니다 / 폰트: 휴먼굴림체'
    },
    {
      variableId: '총주문금액',
      title: '총 주문금액',
      example: '10,000'
    },
    {
      variableId: '총결제금액',
      title: '총 결제금액',
      example: '9,000'
    },
    {
      variableId: '상품가격',
      title: '상품가격',
      example: '10,000'
    },
    {
      variableId: 'receiverName',
      title: '수령인명',
      example: '홍길동'
    },
    {
      variableId: 'receiverPhoneNumber',
      title: '수령인 전화번호',
      example: '01012341234'
    },
    {
      variableId: 'buyerName',
      title: '구매자명',
      example: '홍길동'
    },
    {
      variableId: 'buyerPhoneNumber',
      title: '구매자 전화번호',
      example: '01012341234'
    },
    {
      variableId: 'address',
      title: '배송지',
      example: '서울특별시 용산구 남산공원길 105'
    },
    // {
    //   variableId: 'status',
    //   title: '상품 상태',
    //   example: '배송중, 결제완료, 반품됨, ...'
    // },
    {
      variableId: 'storeName',
      title: '상점명',
      example: '솔라피 스토어'
    },
    // {
    //   variableId: '주문일시',
    //   title: '주문일시',
    //   example: '2024. 4. 4. 오전 10:26:59'
    // },
    {
      variableId: '주문일자',
      title: '주문일자',
      example: '2024. 4. 4.'
    },
    {
      variableId: '주문시각',
      title: '주문시각',
      example: '오전 10:26:59'
    },
    {
      variableId: '주문번호',
      title: '주문번호',
      example: '2024040433012345'
    },
    {
      variableId: '상품주문번호',
      title: '상품주문번호',
      example: '2024040433012310'
    },
    {
      variableId: '택배사',
      title: '택배사명',
      example: 'CJ대한통운'
    },
    {
      variableId: '송장번호',
      title: '송장번호',
      example: '1234123412341'
    },
    {
      variableId: '주문상세정보URL',
      title: '주문상세URL',
      example: 'https://order.pay.naver.com/o/orderStatus/2024040433012345'
    },
    {
      variableId: '배송조회URL',
      title: '배송조회URL',
      example: 'https://~'
    },
    {
      variableId: '리뷰작성URL',
      title: '리뷰작성URL',
      example:
        'https://m.shopping.naver.com/popup/reviews/form?orderNo=2024040433012345&productOrderNos=2024040433012345&closePopup=true'
    },
    {
      variableId: '구매확정URL',
      title: '구매확정URL',
      example:
        'https://order.pay.naver.com/o/purchaseDecision/orders/2024040433012345'
    },
    {
      variableId: '스토어URL',
      title: '내 스토어 URL',
      example: 'https://smartstore.naver.com/textmessage'
    }
  ]
})

const handler = {}

handler.getStores = {
  payloadCreator: commerceAPI.getStores
}

handler.getHooks = {
  payloadCreator: commerceAPI.getHooks
}

handler.getHookInfo = {
  payloadCreator: commerceAPI.getHookInfo
}

handler.createStore = {
  // // 모든 옵션은 선택사항
  // options: {
  //   requestName: '요청 이름 입력',
  //   debounce: {
  //     wait: 500,
  //     leading: true,
  //     trailing: false
  //   },
  //   response: {
  //     // 해당 메시지는 컨테이너에 설정이 존재하는 경우 표시 순서가 2순위로 밀려납니다.
  //     successMessage: '요청에 성공했습니다.',
  //     error: {
  //       ignore: [404, 403], // array or true
  //       helper: {
  //         cond: {
  //           status: 404,
  //           errorCode: 'ResourceNotFound'
  //         },
  //         message:
  //           '위 조건에 부합하는 오류 발생 시 메시지에 부가적인 도움말을 사용자에게 보여줄 수 있습니다.'
  //       }
  //     }
  //   }
  // },
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.createStore
}

handler.deleteStore = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.deleteStore
}

handler.inactivateStore = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.inactivateStore
}

handler.createHook = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.createHook
}

handler.updateHook = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.updateHook
}

handler.deleteHook = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.deleteHook
}

handler.addChannel = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.addChannel
}

handler.getOrderList = {
  payloadCreator: commerceAPI.getOrderList
}

handler.hookTest = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.hookTest
}

handler.activateHook = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.activateHook
}

handler.inactivateHook = {
  options: { debounce: { wait: 500 } },
  payloadCreator: commerceAPI.inactivateHook
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
