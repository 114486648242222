import React from 'react'
import { parsePhoneNumber } from 'awesome-phonenumber'

export const isClassComponent = component => {
  return !!(
    typeof component === 'function' && !!component.prototype.isReactComponent
  )
}

export const isFunctionComponent = component => {
  return !!(
    typeof component === 'function' &&
    String(component).includes('return React.createElement')
  )
}

export const isReactComponent = component => {
  return !!(isClassComponent(component) || isFunctionComponent(component))
}

export const isElement = element => {
  return React.isValidElement(element)
}

export const isDOMTypeElement = element => {
  return isElement(element) && typeof element.type === 'string'
}

export const isCompositeTypeElement = element => {
  return isElement(element) && typeof element.type === 'function'
}

export const isString = string => {
  return Object.prototype.toString.call(string) === '[object String]'
}

export const isNumber = number => {
  return (
    !isNaN(number) &&
    Object.prototype.toString.call(number) === '[object Number]'
  )
}

// 숫자로만 구성된 문자열
export const isNumberString = number => {
  return isNumber(number) || /^[0-9]+$/.test(number)
}

export const isArray = array => {
  return Object.prototype.toString.call(array) === '[object Array]'
}

export const isObject = object => {
  return Object.prototype.toString.call(object) === '[object Object]'
}

export const isBoolean = data => {
  return data === true || data === false
}

export const isEmptyObject = object => {
  return isObject(object) && Object.keys(object).length < 1
}

export const isFunction = func => {
  return (
    Object.prototype.toString.call(func) === '[object Function]' ||
    Object.prototype.toString.call(func) === '[object AsyncFunction]'
  )
}

export const isPromise = p => {
  return isFunction(p?.then)
}

export const isAsyncFunction = func => {
  return Object.prototype.toString.call(func) === '[object AsyncFunction]'
}

export const isEmptyString = data => {
  return (
    data === undefined ||
    data === false ||
    data === null ||
    String(data)
      .trim()
      .replace(/\\t\\s\\n/g, '') === ''
  )
}

export const isPhoneNumber = number => {
  const info = parsePhoneNumber(number)
  if (info?.valid === true) {
    return info?.number?.national
  }
  const isFirstZero = String(number).substring(0, 1) === '0'
  // 숫자 첫째 0이 생략되는 경우
  const addedZeroNumber = isFirstZero ? String(number) : '0' + String(number)
  const formatRegex =
    /^(02|0[0-9]{2})([0-9]{3,4})([0-9]{4})$|^(15..|16..|18..)([0-9]{4})$|^(0(?:3|5)0[0-9]?)([0-9]{3,4})([0-9]{4})$/
  const matches =
    String(number).match(formatRegex) || addedZeroNumber.match(formatRegex)
  const formatNumber = matches
    ? matches.filter((d, index) => index !== 0 && !isEmptyString(d)).join('-')
    : false
  return !isEmptyString(number) && formatNumber
}

export const isPersonalPhoneNumber = number => {
  const formatRegex = /^(01[0-9]{1})([0-9]{3,4})([0-9]{4})$/
  return formatRegex.test(String(number))
}

export const isRegExp = regexp => {
  return Object.prototype.toString.call(regexp) === '[object RegExp]'
}

export const isEmail = email => {
  return (
    !isEmptyString(email) &&
    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  )
}

export const isUrl = (url, requireProtocol = true) => {
  const regexp =
    requireProtocol === true
      ? /^https?:\/\/(www\.)?[a-zA-Z0-9가-힣]{1}[-a-zA-Z0-9@:%._\\+~#=]{0,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/
      : /^[a-zA-Z0-9가-힣]{1}[-a-zA-Z0-9@:%._\\+~#=]{0,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)$/
  return isString(url) && regexp.test(url)
}

export const isUrlScheme = url => {
  const regexp =
    /([a-zA-Z]{2,20}):\/\/([\w_-]+(?:(?:\.[\w_-]+)?))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/
  return isString(url) && regexp.test(url)
}

export const isBusinessNumber = number => {
  if (!isEmptyString(number) && String(number).length === 10) {
    const formatRegex = /^([0-9]{3})([0-9]{2})([0-9]{5})$/
    const matches = String(number).match(formatRegex)
    const formatNumber =
      isArray(matches) && /^[1-9]/.test(String(number))
        ? matches
            .filter((d, index) => index !== 0 && !isEmptyString(d))
            .join('-')
        : false
    return formatNumber
  }
  return false
}

export const isCorporationNumber = number => {
  if (!isEmptyString(number) && String(number).length === 13) {
    const formatRegex = /^([0-9]{6})([0-9]{7})$/
    const matches = String(number).match(formatRegex)
    const formatNumber = isArray(matches)
      ? matches.filter((d, index) => index !== 0 && !isEmptyString(d)).join('-')
      : false
    return formatNumber
  }
  return false
}

export const isIPAddress = ip => {
  const formatRegex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return formatRegex.test(ip)
}

export const isMobile = () => {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\\-(n|u)|c55\/|capi|ccwa|cdm\\-|cell|chtm|cldc|cmd\\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\\-s|devi|dica|dmob|do(c|p)o|ds(12|\\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\\-|_)|g1 u|g560|gene|gf\\-5|g\\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\\-(m|p|t)|hei\\-|hi(pt|ta)|hp( i|ip)|hs\\-c|ht(c(\\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\\-(20|go|ma)|i230|iac( |\\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\\-[a-w])|libw|lynx|m1\\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\\-2|po(ck|rt|se)|prox|psio|pt\\-g|qa\\-a|qc(07|12|21|32|60|\\-[2-7]|i\\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\\-|oo|p\\-)|sdk\/|se(c(\\-|0|1)|47|mc|nd|ri)|sgh\\-|shar|sie(\\-|m)|sk\\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\\-|v\\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\\-|tdg\\-|tel(i|m)|tim\\-|t\\-mo|to(pl|sh)|ts(70|m\\-|m3|m5)|tx\\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\\-|your|zeto|zte\\-/i.test(
        a.substr(0, 4)
      )
    ) {
      check = true
    }
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}

export const isMemberId = memberId => {
  return /^MEM[a-zA-Z0-9_\-~]{11}$/.test(memberId)
}

export default {
  isClassComponent,
  isFunctionComponent,
  isReactComponent,
  isElement,
  isDOMTypeElement,
  isCompositeTypeElement,
  isString,
  isArray,
  isObject,
  isEmptyObject,
  isFunction,
  isPromise,
  isAsyncFunction,
  isNumber,
  isNumberString,
  isEmptyString,
  isRegExp,
  isEmail,
  isUrl,
  isUrlScheme,
  isBusinessNumber,
  isPersonalPhoneNumber,
  isMobile,
  isIPAddress,
  isMemberId
}
