/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import { bindStateToProps, bindActionCreators } from 'lib'
import cookies from 'js-cookie'
// import classNames from 'classnames'

// material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
// import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

import { actions as popupActions } from 'store/modules/popup'
import { actions as confirmActions } from 'store/modules/confirm'
import { actions as usersActions } from 'store/modules/users'

// my components
import withCompWidth from 'commonHoc/withCompWidth'
import TokenExpFormatText from 'components/atoms/TokenExpFormatText'
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
// import CommonButton from 'containers/CommonButtonContainer'

// libraries
import getComponentId from 'lib/getComponentId'
import logout from 'lib/logout'
import config from 'config'
// import { isArray } from 'lib/detectType'
// import isEqualProps from 'lib/isEqualProps'
// import {
//   makeStyleObject as ms,
//   getClassName as cn,
//   getComponentByBreakpoint as gc
// } from 'lib/getBreakPoints'

export const useProps = {
  //  testVar: { default: 'hello world!', type: 'string.isRequired' }
}
/**
 * 로그인 연장 팝업
 */
const LogoutConfirm = ({ classes: cls, ...props }) => {
  const { ConfirmActions, PopupActions, UsersActions, popupKey } = props
  const confirm = params => {
    return new Promise((resolve, reject) => {
      ConfirmActions.show({
        ...params,
        onConfirm: resolve,
        onCacnel: reject
      })
    })
  }
  return (
    <div data-compid={getComponentId('LogoutConfirm')} className={cls.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography align="center">
            <strong>
              <TokenExpFormatText />
            </strong>{' '}
            후 자동 로그아웃 됩니다.
          </Typography>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={async () => {
              await confirm({ message: '로그인 시간을 연장할까요?' })
              await UsersActions.reissueToken()
              cookies.remove('tokenRefresh', {
                domain: config?.cookieDomain,
                path: ''
              })
              PopupActions.close({ key: popupKey })
              confirm({
                message: '로그인 연장되었습니다.',
                popupMessage: true,
                severity: 'success'
              })
            }}
          >
            로그인 연장
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={async () => {
              await confirm({
                message:
                  '즉시 로그아웃 할까요? 진행중인 작업이 있으면 저장되지 않습니다.',
                severity: 'warning'
              })
              logout({ withRedirect: true })
            }}
          >
            즉시 로그아웃
          </Button>
        </Grid>
        <Grid item xs>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              PopupActions.close({ key: popupKey })
              cookies.set('tokenRefresh', 'NEED', {
                domain: config?.cookieDomain,
                path: ''
              })
            }}
          >
            무시
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

LogoutConfirm.defaultProps = setDefaultProps(useProps)
LogoutConfirm.propTypes = setPropTypes(useProps)
const commonStyles = theme => ({
  root: {}
  // ,test: { fontSize: 12 },
  // ...ms('test', 'sm', { fontSize: 15 }),
  // ...ms('test', 'md', { fontSize: 20 })
})

export default connect(
  bindStateToProps({}),
  bindActionCreators({
    confirmActions,
    popupActions,
    usersActions
  })
)(
  React.memo(
    withStyles(commonStyles)(withCompWidth(LogoutConfirm, { enabled: false }))
    // ,(prevProps, nextProps) => {
    //   return isEqualProps(nextProps, prevProps)
    // }
  )
)
