import { Map } from 'immutable'
import { dashboard as dashboardAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  zendeskData: {},
  accountInfo: {},
  verificationInfo: [],
  approvedSenderIds: undefined,
  notApprovedSenderIds: [],
  isExpanded: true,
  todayUseMessage: {},
  importantNotice: []
})
const handler = {
  getZendeskData: {
    options: { response: { error: { ignore: true } } },
    payloadCreator: dashboardAPI.getZendeskData
  },
  getAccountInfo: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: dashboardAPI.getAccountInfo
  },
  getPersonVerifications: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: dashboardAPI.getPersonVerifications
  },
  getBusinessVerifications: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: dashboardAPI.getBusinessVerifications
  },
  getSenderIds: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: dashboardAPI.getSenderIds,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { senderIds = [] } = action.payload
        const approvedSenderIds = senderIds.filter(
          senderId => senderId.status === 'ACTIVE'
        )
        const notApprovedSenderIds = senderIds.filter(
          senderId => senderId.status !== 'ACTIVE'
        )
        const stateData = {
          approvedSenderIds,
          notApprovedSenderIds
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  setIsExpanded: {
    reducer: (state, action) => {
      return state.set('isExpanded', action.payload)
    }
  },
  getTodayUseMessage: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: dashboardAPI.getTodayUseMessage,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const todayUseMessage = action.payload
        const stateData = { todayUseMessage }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  getImportantNotice: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: dashboardAPI.getImportantNotice
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
