const getCookieDomain = () => {
  const hostname = window.location.hostname.split('.')
  const prefix = hostname.shift()
  if (prefix === 'localhost') return prefix
  if (prefix === 'console') {
    return `.${hostname.join('.')}`
  }
  return `.${prefix}.${hostname.join('.')}`
}
export default getCookieDomain
