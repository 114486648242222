import axios from 'axios'
import qs from 'qs'
import { omitBy, isUndefined } from 'lodash'

export const getSenderIdList = params => {
  const query = qs.stringify(omitBy(params, isUndefined))
  const uri = `/senderid/v1/numbers${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getSenderIdData = params => {
  const query = qs.stringify(omitBy(params, isUndefined))
  const uri = `/senderid/v1/numbers${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getDocumentInfo = params => {
  const { documentId } = params
  const uri = `/senderid/v1/documents/${documentId}`
  return axios.get(uri)
}

export const createSenderId = params => {
  const { phoneNumber } = params
  const uri = `/senderid/v1/numbers`
  return axios.post(uri, { phoneNumber })
}

export const duplicateConfirmSenderId = params => {
  const { phoneNumber, reason } = params
  const uri = `/senderid/v1/papers/duplicate/${phoneNumber}`
  return axios.post(uri, { reason })
}

export const uploadDocuments = params => {
  const uri = `/senderid/v1/documents`
  return axios.post(uri, params)
}

export const getDocuments = params => {
  const { category } = params
  const uri = `/senderid/v1/documents?category=${category}`
  return axios.get(uri)
}

export const authenticateSenderId = params => {
  const { phoneNumber } = params
  const uri = `/senderid/v1/numbers/${phoneNumber}/authenticate`
  return axios.put(uri, {})
}

export const authDocsSenderId = payload => {
  const { phoneNumber, ...params } = payload
  const uri = `/senderid/v1/papers/approval/${phoneNumber}`
  return axios.post(uri, params)
}

export const deleteSenderId = params => {
  const { phoneNumber } = params
  const uri = `/senderid/v1/numbers/${phoneNumber}`
  return axios.delete(uri)
}

export const updateSenderId = params => {
  const { phoneNumber, status } = params
  const uri = `/senderid/v1/numbers/${phoneNumber}`
  return axios.put(uri, { status })
}

// 본인인증 후 등록
export const activatedNumbers = params => {
  const uri = `/senderid/v1/activated-numbers`
  return axios.post(uri, params)
}

export const createConsignmentSenderIds = params => {
  const { phoneNumber, documents } = params
  const uri = `/senderid/v1/papers/consignment/${phoneNumber}`
  return axios.post(uri, { documents })
}

export const checkPhoneNumber = params => {
  const { phoneNumber } = params
  const uri = `/senderid/v1/check-numbers/${phoneNumber}`
  return axios.get(uri)
}

export const createLimitationSenderIds = params => {
  const { documents, limit } = params
  const uri = `/senderid/v1/papers/limitation`
  return axios.post(uri, { documents, limit })
}

export const getActivatedSenderIds = () => {
  const uri = `/senderid/v1/numbers/active`
  return axios.get(uri)
}

export const getExtra = payload => {
  const query = qs.stringify(omitBy(payload, isUndefined))
  const uri = `/senderid/v1/extra-data/numbers${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const setExtra = payload => {
  const uri = `/senderid/v1/extra-data/numbers`
  return axios.post(uri, payload)
}
