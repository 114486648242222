import axios from 'axios'
import qs from 'querystring'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

export const getMemberList = payload => {
  const { accountId, ...params } = payload
  const query = qs.stringify(params)
  return axios.get(
    `/users/v1/accounts/${accountId}/members${params ? '?' + query : ''} `
  )
}

export const getAccountData = accountId => {
  const uri = `/users/v1/accounts/${accountId}`
  return axios.get(uri)
}

export const getAccounts = payload => {
  const query = qs.stringify(payload)
  const uri = `/users/v1/accounts${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const deleteAccount = accountId => {
  return axios.delete(`/users/v1/accounts/${accountId}`, {
    withCredentials: true
  })
}

export const leaveAccount = accountId => {
  return axios.delete(`/users/v1/accounts/${accountId}/me`, {
    withCredentials: true
  })
}

export const getMysiteMembers = payload => {
  const { appId, ...params } = payload
  const query = qs.stringify(params)
  const uri = `/users/v1/member/mysite/${appId}${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getMysiteAccounts = payload => {
  const { appId, ...params } = payload
  const query = qs.stringify(params)
  const uri = `/users/v1/accounts/mysite/${appId}${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const createMysiteAccountExtends = payload => {
  const { appId, ...params } = payload
  const uri = `/users/v1/accounts/mysite/${appId}/extends`
  return axios.post(uri, params)
}

export const deleteMysiteAccountExtends = payload => {
  const { appId, ...params } = payload
  const uri = `/users/v1/accounts/mysite/${appId}/extends`
  return axios.delete(uri, { data: params })
}

export const getMemberInfo = () => axios.get(`/users/v1/member`)

export const editMemberInfo = (data = {}) => {
  const { accountId, memberId, ...params } = data
  return axios.put(
    `/users/v1/accounts/${accountId}/members/${memberId}`,
    params
  )
}

export const deleteMember = data => {
  const { accountId, memberId } = data
  return axios.delete(`/users/v1/accounts/${accountId}/members/${memberId}`)
}

export const deletePhoneNumber = () => {
  return axios.delete(`/users/v1/member/phone-number`)
}

export const activeDeviceAuth = (data = {}) => {
  return axios.post(`/users/v1/member/devices/auth`, data, {
    withCredentials: true
  })
}

export const getDeviceInfo = () => {
  return axios.get(`/users/v1/member/devices`)
}

export const deleteDeviceInfo = payload => {
  return axios.delete(`/users/v1/member/devices`, { data: payload })
}

export const insertDeviceInfo = payload => {
  return axios.post(`/users/v1/member/devices`, payload)
}

export const updateMember = payload => {
  return axios.put(`/users/v1/member`, payload)
}

export const updatePhoneNumber = payload => {
  return axios.put(`/users/v1/member/phone-number`, payload)
}

export const getMyAccountInfo = (payload, options = {}) => {
  const { cache = false } = options
  const { accountId } = payload
  return axios.get(`/users/v1/accounts/${accountId}/me`, { cache })
}

export const updatePassword = payload => {
  return axios.put(`/users/v1/member/password`, payload)
}

export const leaveService = () => {
  return axios.delete(`/users/v1/member`, {
    headers: { withCredentials: true }
  })
}

export const trialSignUp = payload => {
  return axios.post(`/users/v1/trial/signup`, payload, {
    withCredentials: true
  })
}

export const convertTrial = payload => {
  return axios.post(`/users/v1/trial/convert`, payload, {
    withCredentials: true
  })
}

export const getMyPreferences = () => {
  return axios.get(`/users/v1/preferences/me`)
}

export const updateMyPreferences = payload => {
  const token = jwt.decode(cookies.get('CSAK')) || {}
  const advancedMode = cookies.get('advancedMode')
  if (!advancedMode && token?.targetAccountId) {
    return Promise.resolve()
  }
  return axios.put(`/users/v1/preferences/me`, { value: payload })
}

export const activateMember = () => {
  return axios.put(`/users/v1/member/activate`)
}

export const inviteMember = payload => {
  const { email, role } = payload
  return axios.post(`/users/v1/invitations`, { email, role })
}

export const getInvitationList = accountId => {
  return axios.get(`/users/v1/accounts/${accountId}/invitations`)
}

export const cancelInvitation = invitationId => {
  return axios.delete(`/users/v1/invitations/${invitationId}`)
}

export const updateAccount = payload => {
  const { accountId, ...params } = payload
  return axios.put(`/users/v1/accounts/${accountId}`, params)
}

export const updateAccountMember = payload => {
  const { accountId, memberId, ...params } = payload
  return axios.put(
    `/users/v1/accounts/${accountId}/members/${memberId}`,
    params
  )
}

export const updateEmail = payload => {
  const { email } = payload
  return axios.put(`/users/v1/member/email`, { email })
}

export const openChats = () => {
  return axios.post(`/users/v1/member/chats`)
}

export const postMessage = payload => {
  const { userChatId, message } = payload
  return axios.post(`/users/v1/member/chats/${userChatId}/messages`, {
    message
  })
}

export const existenceEmail = async email => {
  const uri = `/users/v1/existence/email/${email}`
  return axios.get(uri)
}

export const reissueToken = () => {
  const uri = `users/v1/accounts/reissue-token`
  return axios.post(uri, {}, { withCredentials: true })
}
