import axios from 'axios'
import qs from 'qs'
// import omitBy from 'lodash/omitBy'
// import isUndefined from 'lodash/isUndefined'

export const createBalanceHistoryDownload = (payload = {}) => {
  const { query, params = {} } = payload
  const queryString = qs.stringify(query)
  const uri = `/download/v1/balance-history/csv${
    queryString ? '?' + queryString : ''
  }`
  return axios.post(uri, params)
}

export const createRechargeHistoryDownload = (payload = {}) => {
  const { query, params = {} } = payload
  const queryString = qs.stringify(query)
  const uri = `/download/v1/recharge-history/csv${
    queryString ? '?' + queryString : ''
  }`
  return axios.post(uri, params)
}

export const createMessagesDownload = (query = '') => {
  const uri = `/download/v1/messages/csv${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getDownloadStatus = ({ historyId } = {}) => {
  // const queryString = qs.stringify(params)
  const uri = `/download/v1/histories/${historyId}`
  return axios.get(uri)
}
